import React, {useState} from 'react';
import './footer2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'


import logo from "../../assets/images/logo2/ponos-logo2.svg";


const Footer2 = () => {

  const linkedin = <FontAwesomeIcon
   className='fontAweosmeX' 
   icon={faLinkedin} 
   style={{color: "#000000",fontSize: '22px', 
  transition: 'color 0.3s ease-in', }} />
     const linkedinHover = <FontAwesomeIcon 
     className='fontAweosmeX' 
     icon={faLinkedin} 
     style={{color: "#0F62FE",fontSize: '22px',
    transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />

     const xTwitter = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareXTwitter} 
     style={{color: "#000000",fontSize: '22px',
    transition: 'color 0.3s ease-in', }} />
     const xTwitterHover = <FontAwesomeIcon className='fontAweosmeX' icon={faSquareXTwitter} 
     style={{color: "#0F62FE",fontSize: '22px',
    transition: 'color 0.3s ease-in, transform 0.3s ease-in', }} />

     const keys = [1,2]
   const imageKey = keys.map(key=>{
    return key
   })
   

   const [isHover, setIsHover] = useState(-1);
  return (
    <section id="contact">
        <div className="container footer2-main">
               <div className='footer2 footer2_section__padding'>
        <div className='footer2-heading'>
         
            <h1>Get In Touch</h1>
             <p>Drop us a line about you or your project and we will get back to you ASAP!</p>
        </div>
        {/* <div className='footer2-btn'>
            <p><a className='nowrap' href="mailto:info@ponos.technology">Contact us</a></p>
        </div> */}
         <a href="mailto:info@ponos.technology" className='footer2-btn-new no-wrap'>
                          <span>Contact us</span>
                         </a>
                         {/* pocetak/////////////////////////////////// */}
        <div className='footer2-links'>
          <div className='footer2-links_logo'>
           
              <p className='footer2-logo-wrapper'>
                   <span className="footer2-logo-content">
                   <span className="footer2-logo-img"><img src={logo} alt="Ponos" /></span> 
                  <span className="footer2-logo-text ">PONOS</span>
                
                  </span> 
                </p>

                  <p className='footer2-paragraf'><span className='nowrap'>Zero-Knowledge</span> Proof Commoditization by <span className='nowrap'>HW-SW</span> <span className='nowrap'>Co-design</span></p>

                
          </div>

          <div className='footer2_links-two-right'>

              <div className='footer2-links_div1'>
            <h4>Follow us:</h4>
              <p className='p-l-social'>
              <a
              onMouseEnter={()=>setIsHover(imageKey[1])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://www.linkedin.com/company/ponostechnology/" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[1]
                    
                    ? linkedinHover
                    : linkedin

                   }
              </a>
              </p> 

               
               <p className='p-l-social'>
                <a
               onMouseEnter={()=>setIsHover(imageKey[2])}
              onMouseLeave={()=>setIsHover(-1)}
              href="https://twitter.com/PonosTechnology" target="_blank" rel="noopener noreferrer">
              { isHover === imageKey[2]

                    ? xTwitterHover
                    : xTwitter

                   }
              </a>
              </p> 
             {/* <div className='footer2-social'>
                   
             </div> */}
            
             
          </div> 


             <div className='footer2-links_div2'>
            <h4>Contact</h4>
              <p>Email: info@ponos.technology</p>
               <p>Ponos Technology AG</p>
               <p>Gotthardstrasse 26. Zug, Switzerland</p> 
          </div>

          </div>

        </div>
          {/* kraj/////////////////////////////////// */}
        <div className='footer2-copyright'>
          <hr />
        <p>Copyright © 2023 Ponos Technology</p> 
          
        </div>
    </div>
            </div>
       </section>

  )
}

export default Footer2
