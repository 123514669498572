import React from 'react'
import './partners2.css'


import telos1 from "../../assets/images/partners/telos-new.svg"
import polygon1 from "../../assets/images/partners/polygon-brand.svg"
  import ethernal1 from "../../assets/images/partners/ethernal-logo-mark-new.svg"
  import cvlabs from "../../assets/images/partners/cv-labs.svg"
  import ethereumFoundation from "../../assets/images/partners/ethereum-foundation-2.svg"
  import risczero from "../../assets/images/partners/riscZero.svg"


function Partners2() {
  return (
    <section id='partners2'>
        <div className='container partners2-main animation-element'>

            <div className='partners2-title-main'>
                <h4>
                    <div className='partners2-title-wrapper'>Meet our partners</div>
                    </h4>

                
            </div>

             <div className='partners2-logo-main'>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://polygon.technology/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={polygon1} alt="polygon" className={"partners2-filter-img-polygon"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://www.telos.net/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={telos1} alt="telos" className={"partners2-filter-img-telos"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper2'>
                    <div className='partners2-link-wrapper2'>
                        <a href="https://risczero.com/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={risczero} alt="riscZero" className={"partners2-filter-img-risczero"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://ethernal.tech/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={ethernal1} alt="ethernal" className={"partners2-filter-img"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper3'>
                     <div className='partners2-link-wrapper3'>
                        <a href="https://www.cvlabs.com/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={cvlabs} alt="cvlabs" className={"partners2-filter-img-cvlabs"}/> 
                        
                        </a>
                    </div>
                 </div>
                 <div className='partners2-logo-wrapper'>
                    <div>
                        <a href="https://ethereum.foundation/" target="_blank" rel="noopener noreferrer" className='partners2-filter-main partners2-img-relative'> 

                        <img src={ethereumFoundation} alt="ethereumFoundation" className={"partners2-filter-img-ef"}/> 
                        
                        </a>
                    </div>
                 </div>
                    
                </div>
            
        </div>
    </section>
  )
}

export default Partners2