import React from 'react';
import "./rds2.css";


 import development from "../../assets/images/services/dev-img.svg"
import research from "../../assets/images/services/res-img.svg"
//import consulting from "../../assets/images/services/con-img.svg"


const Rds2 = () => {
  return (
   <section id='services'>
    
     <div className='container main-rds2'>
           <div className='row rds2-content'>
                 <div className='col-lg-6 col-md-6 col-sm-12 rds12'>
                    <h2 className='rds2-main-title'>Services</h2>
                </div>
            </div>
          
           <div className='row rds2-text-main rds2-bottom-line rds2-column-reverse'>  
          
           <div className='col-lg-5 col-md-5 col-sm-12 rds2-col'>
             <h4 className='rds2-subtitle'>Development</h4>
                     

                       <p>Design and implementation of modular, <span className='nowrap'>full-stack,</span> <span className='nowrap'>end-to-end,</span> fully optimized blockchain infrastructure solutions. Specialized in dedicated <span className='nowrap'>HW-SW</span> <span className='nowrap'>co-design</span> of <span className='nowrap'>Zero-Knowledge</span> Proof protocols, leveraging primarily highly optimized architectures built on FPGA.</p>

                       

           </div>
            <div className='col-lg-4 col-md-4 col-sm-12 rds2-col-img'>
               <img className='rds2-img-development' src={development} alt="development" />
           </div>
             
             
              
            </div>
                   {/* rds2-bottom-line */}
                   <div className='row rds2-text-main '> 
                   <div className='col-lg-4 col-md-4 col-sm-12 rds2-col-img'>
                      <img className='rds2-img-research' src={research} alt="research" /> 
             </div>
             <div className='col-lg-5 col-md-5 col-sm-12 rds2-col'>
                        <h4 className='rds2-subtitle'>Research</h4>
               <p>We strive to find innovative solutions to enhance various <span className='nowrap'>Zero-Knowledge</span> Proof architectures by utilizing advanced hardware-software co-design methods, with a final goal of executing an entire system on a fully optimized SoC.</p>
             </div>
             </div>

            
             {/* <div className='row rds2-text-main rds2-column-reverse'> 
             
             <div className='col-lg-5 col-md-5 col-sm-12 rds2-col'>
                   <h4 className='rds2-subtitle'>Consulting</h4>
              <p>Ponos Technology provides wide range of services spanning from algorithms optimization and security auditing to design assessment and education.</p> 
             </div>
              <div className='col-lg-4 col-md-4 col-sm-12 rds2-col-img'>
               <img className='rds2-img-consulting' src={consulting} alt="consulting" /> 
                    
                    
            
             </div> 

            </div> */}
            
        </div>
   </section>
  )
}

export default Rds2
