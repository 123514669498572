import React from 'react';

import './hiring2.css';

import careers from "../../assets/images/careers/careers-img.svg";

 import {JobSingle} from "../../components"



const Hiring1 = () => {

   
  return (
     <section id='careers'>
        <div className="container hiring-main">
           <div className='row hiring-row1'>
                 <div className='col-lg-6 col-md-6 col-sm-12 hiring-col1 '>
                    <h2 className='hiring-main-title'>Careers</h2>
                   
                </div>
                 
            </div>
            
             <div className='row hiring1-open-position-row1'>
               <div className='col-lg-6 col-md-6 col-sm-12 hiring1-open-position-header'>
                    
                        <h4>WE ARE HIRING!</h4> 
                        
                   </div>
                   
            </div> 
            

    <div className='row hiring1-open-position'>

        
        
                  <div className='col-lg-8 col-md-12 col-sm-12 hiring1-open-position-main'>

                        <p>Ponos Technology is looking for HW/SW Design Expert With Strong Experience in Xilinx Vitis Environment</p>

                          {/* <p className='hiring2-open-position-link'><span>
                           <a href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
                              Apply
                         </a>
                            </span></p> */}

                                 
                           {/* <a className='btn-apply' href="https://www.linkedin.com/company/ethernal-tech/" target="_blank" rel="noopener noreferrer">
                           <span>Apply</span>  
                         </a> */}
                    
                        {/* modal start */}
                        
 <button type="button" className="h-primary btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Job description
</button>
 
<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Job description</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <JobSingle/>
         
      </div>
      <div className="modal-footer">
        <button type="button" className="h-secondary btn btn-secondary" data-bs-dismiss="modal">Close</button>
        
      </div>
    </div>
  </div>
</div> 
                        {/* modal end */}
 
                   </div>

                   
                       
     </div> 
                 
            <div className='row open-position-row2'>
                    <div className='col-lg-10 col-md-12 col-sm-12 open-position-footer'>
                        <img src={careers} alt="careers-hiring" />
                         <p>If you are passionate about building a decentralised future please<span className='nowrap'> contact us!</span></p>

                   </div>

                  </div>          
             

         </div>

        
        
     </section>
  )
}

export default Hiring1
