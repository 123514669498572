import React from 'react'
import './projects2.css'
// import DemoModal from "../projects2/DemoModal"



import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

import project1 from "../../assets/images/projects/polygon-zkevm.svg";
import project2 from "../../assets/images/projects/ponos-research.svg";
//import project3 from "../../assets/images/projects/telos-brand.svg";
import project3 from "../../assets/images/projects/telos-new.svg";
import project4 from "../../assets/images/projects/ethereum-foundation-2.svg";



const Projects2 = () => {
 
  
   const options ={
    
     items:1,
      center:true,
      dots:false,
      nav: true,
      navText:[ "", ""],
      loop:true,
        margin: 10,
        responsiveClass:true,
    responsive:{
        0:{
            items: 1,
           
        },
        600:{
            items: 1,
            
            
        },
        1000:{
            items: 1,
           
            
        }
        
    }

  }

  return (
    <section id='projects'>
        <div className='container projects-main'>
          <div className='row projects-row1'>
            <div className='col-lg-6 col-md-6 col-sm-12 projects-col1'>
              <h2 className='projects-col1-title'>Projects</h2>
                 
            </div>
          </div>
              
               <div className='projects2-owl-wrapper'>
                 <OwlCarousel className='owl-theme' {...options}>



             <div className="row p-sl item">
             
              <div className="col-lg-6 col-md-12 col-sm-12 p-sl-text">
                
                <h4>Accelerating core Polygon zkEVM components</h4>
               {/* uz demomodal button paragraf */}
                {/* <p className='p1'>Ponos Technology designed and implemented custom built solutions in FPGA technology for critical components of <span><a href="https://polygon.technology/solutions/polygon-zkevm" target="_blank" rel="noopener noreferrer" className='nowrap'>Polygon zkEVM.</a></span>
                </p> */}

                 <p className='p1'>Ponos Technology designed and implemented custom built solutions in FPGA technology for critical components of Polygon zkEVM.
                </p>
                
                <p className='p2'><span><a href="https://polygon.technology/solutions/polygon-zkevm" target="_blank" rel="noopener noreferrer" className='nowrap'>About Polygon zkEVM</a></span></p> 
                
                {/* <p className='p2'><span><a href="#" target="_blank" rel="noopener noreferrer" className='nowrap'>REQUEST A DEMO</a></span></p> */}

                 {/* <DemoModal/> */}
                 
               
              </div>
               <div className="col-lg-4 col-md-12 col-sm-12 p-sl-img " >
                 <img src={project1} alt="img"  />
                  <p className='projects-polygon-text no-wrap'>Polygon <span>zkEVM</span></p>
              </div>
              
            </div>     

            <div className="row p-sl item">
              
              <div className="col-lg-6 col-md-12 col-sm-12 p-sl-text">
                 
                <h4>Hardware-Accelerated zkEVM <span className='nowrap'>Layer 2</span></h4>
                <p className='p1'>Ponos Technology collaborates with Telos on the development of a hardware-accelerated zkEVM L2 Ethereum network.</p>
                 <p className='p2'><span><a href="https://www.telos.net/post/telos-to-develop-a-hardware-accelerated-zkevm-layer-2-in-partnership-with-ponos-technology" target="_blank" rel="noopener noreferrer">Read more</a></span></p> 
                 {/* className='nowrap' */}
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 p-sl-img ">
                <img className='p-sl-img-telos' src={project3} alt="img"  />

               
                 {/* <p className='projects-ponos-text no-wrap'>The Telos <span>Foundation</span></p> */}

                 
               
              </div>


            </div>

              <div className="row p-sl item">
              
              <div className="col-lg-6 col-md-12 col-sm-12 p-sl-text">
                 
                <h4>Ethereum Foundation Grants Program</h4>
                <p className='p1'>Cryptanalysis and Design of Symmetric Cryptographic Primitives for Zero-Knowledge Applications.</p>
                 {/* <p className='p2'><span><a href="https://ethereum.foundation/" target="_blank" rel="noopener noreferrer">Read more</a></span></p>  */}
                 
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 p-sl-img ">
                <img className='p-sl-img-ef' src={project4} alt="img"  />

               
                 {/* <p className='projects-ponos-text no-wrap'>The Telos <span>Foundation</span></p> */}

                 
               
              </div>


            </div>

            
             
            <div className="row p-sl item">
              
              <div className="col-lg-6 col-md-12 col-sm-12 p-sl-text">
                 
                <h4>Faster, more sustainable and secure hash functions</h4>
                <p>Ponos research group is devoted to design and implementation of more sustainable thought bulletproof secure hardware friendly hash functions. Moreover, we are investigating and creating solutions for advanced computational modules used in zkSTARKS and SNARKS such as NTT/ iNTT and MSM architectures.</p>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 p-sl-img ">
                <img src={project2} alt="img"  />

               
                 <p className='projects-ponos-text no-wrap'>Ponos <span>Research</span></p>

                 
               
              </div>


            </div>

             </OwlCarousel>

               </div>
            
        </div>
    </section>
  )
}

export default Projects2
