
import './App.css';
import {  Navbar, Header2,Partners2,Products, Rds2,Projects2, AboutUs, Team,Hiring1, Footer2} from './components'

function App() {

  return (
    
     <div>

       <Navbar/> 
       <Header2/>
       <Partners2/>
       <Products/>
       <Rds2/>
       <Projects2/>
       <AboutUs/>
       <Team/>
       <Hiring1/>
      <Footer2/>
  
   </div>
  
  
  );
}

export default App;
