import React from 'react';

import './header2.css';


const Header2 = () => {
  
  return (
    
       <section id="header">
        
            <div className="container header2_main">
                <div className="header2-content">
                  <div className='header2-text-wrapper animation-wrapper'>


                                <h1 className='title1 animation-wrapper1'>Ponos Technology</h1>
                          
                                 <h1 className='title2 animation-wrapper2'><span className='nowrap'>Zero-Knowledge</span> Proof Commoditization by <span className='nowrap'>HW-SW</span> <span className='nowrap'>Co-design</span></h1>

                       
                      </div>
                  
                    
                </div> 
                
             
            </div>
          
           
              
        </section>
   
  )
}

export default Header2
