import React from 'react'
import './jobSingle.css';

const JobSingle = () => {
  return (
     <section id='jobSingle'>
    
        <div className='container jobSingle-container'>
          <div className='row jobSingle-section-main'>
                 <div className='col-lg-8 col-md-12 col-sm-12 jobSingle-main-title'>
                    <h2 className='jobSingle-section-title'>HW/SW design expert with strong experience in Xilinx Vitis Environment</h2>
                     
                </div>
               
               
            </div> 
           
           <div className='row jobSingle-text-main'>  

             <div className='col-lg-8 col-md-12 col-sm-12 jobSingle-text-right'>

             
               
                <div className='jobSingle-col'>
                  <h3>About the job</h3>
                 <p className='jobSingle-col-p'>Web3 promises a decentralized future. To make it a reality, efficiency gains of several orders of magnitude have to be achieved. We at PONOS Technology are taking on these tremendous challenges by applying our cutting edge expertise and engineering skills to the latest research results. At PONOS Technology you will:
                   </p>
                   <ul>
                    <li>Take part of a small, but elite team of engineers as a consultant, part-time or eventually full time employee</li>
                    <li>Apply your knowledge to achieve efficiency gains of an order of magnitude by accelerating software critical modules in FPGA and removing bottlenecks</li>
                    <li>Work on a cutting edge hardware</li>
                    <li>Receive a competitive package</li>
                    <li>Work remotely or in our offices in Zug/ Belgrade</li>
                   </ul>
                   <p className='jobSingle-col-p'>If you know how to smartly utilize Xilinx tools to accelerate compute-heavy algorithms, we can start collaborating.</p>
            </div>
            <div className='jobSingle-col'>
                   <h3>Responsibilities</h3> 
                   <ul>
                    <li>Utilize Xilinx Vitis to develop host application and customize firmware</li>
                    <li>Employ RTL kernel flow for HW integration on Alveo boards</li>
                    <li>Develop efficient interface/communication with kernel using OpenCL and/or Xilinx XRT API</li>
                    <li>Algorithm understanding in C/C++, Python or Rust and ability to translate them to efficient RTL code</li>
                    <li>Simulation, synthesis, implementation and debugging on FPGAs (Xilinx Vitis toolset)</li>
                   </ul>
               
                 
             </div>
              
             <div className='jobSingle-col'>

                   <h3>Qualifications</h3>
                   <ul>
                    <li>You have applied your deep knowledge of the above and implemented relevant projects on Xilinx FPGAs boards using the proper toolset as a proof of concept, prototype, or product</li>
                    <li>You have a BSc/MSc/PhD in computer science/engineering, electrical engineering or similar discipline</li>
                    <li>Solid experience with newer Xilinx evaluation/prototyping boards (Series 7, UltraScale(+)) and development environment</li>
                   </ul>
                    
                     </div>

             
             
           </div>

             
              
            </div>


            <div className='row jobSingle-section-main-bottom'>
                
                <div className='col-lg-2 col-md-4 col-sm-4 jobSingle-main-title-bottom'>
                    
                      {/* <div className='jobSingle-btn'>
                      <p><a className='nowrap' href="mailto:info@ponos.technology">Apply now!</a></p>
                    </div> */}
                    <a href="mailto:info@ponos.technology" className='jobSingle-btn-new no-wrap'>
                          <span>Apply now!</span>
                         </a>
                </div>

               
            </div> 

            
        </div>
    
       
    </section>
  )
}

export default JobSingle
