import React from 'react';
import "./aboutUs.css";

// import {PartnersSingle} from "../../components"
//import {PartnersSingleLine} from "../../components"
// import {Member} from "../../components"
// import {MemberMob} from "../../components"


const AboutUs = () => {
  return (
    <section id='about'>
     
        <div className='container aboutus-container'>
          <div className='row about-section-main'>
                 <div className='col-lg-6 col-md-6 col-sm-12 about-main-title'>
                    <h2 className='about-section-title'>About</h2>
                </div>
            </div> 
           
           <div className='row aboutus-text-main'>  

             <div className='col-lg-8 col-md-12 col-sm-12 aboutus-text-right'>

               
                <div className='aboutus-col'>
                 <p>Our vision is a fully decentralized, absolutely secure and lightning fast still sustainable Web3 guaranteeing ultimate privacy!</p>
            </div>
            <div className='aboutus-col'>
                    
                

                <p>Our mission is to provide cutting-edge HW-SW solutions for core blockchain infrastructures, by optimally matching modular design, highly optimized implementation, and deployment scenarios. This primarily concerns computationally expensive <span className='nowrap'>Zero-Knowledge</span> Proofs, enabling in such a way a breakthrough in distributed client-side proof generation and spurring massive adoption of Web3 solutions.</p>
                 
             </div>

             
              
             <div className='aboutus-col'>
                   
                    <p>We are strongly committed to creating more efficient and sustainable proving systems as these require an enormous amount of computing power and energy. Our goal is to unlock the full potential of Web3 by developing the most energy-efficient computing technologies.</p>
                     </div>

             {/* <PartnersSingleLine/> 
             <PartnersSingle/> */}
             {/* <Member/>
             <MemberMob/> */}
             
           </div>

             
              
            </div>

            
        </div>

       
    </section>
  )
}

export default AboutUs
